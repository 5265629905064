import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
    hideHeader,
    hideBottomNav,
    hideSideDrawer,
} from "../../../state/actions";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "reactstrap";
import * as Strapi from "../../../hooks/strapi";
import Heading from "../../atoms/Heading/Heading";
import Paragraph from "../../atoms/Paragraph/Paragraph";

import HomeCard from "../../molecules/HomeCard/HomeCard";
import Modal from "../../molecules/Modal/Modal";

import "./home.scss";

const Home = () => {
    const dispatch = useDispatch();
    const global = Strapi.useGlobal();

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());
        dispatch(hideSideDrawer());

        document.body.classList.add("page-home");

        return () => {
            document.body.classList.remove("page-home");
        };
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <div className="home">
                        <div className="home__logo">
                            <GatsbyImage
                                image={getImage(global.logo_ue.localFile)}
                                alt={global.logo_ue.alternativeText}
                            />
                        </div>
                        <HomeCard />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
