import { navigate } from "gatsby";
import React from "react";
import { useAboutModal } from "../../../hooks/redux";
import * as Strapi from "../../../hooks/strapi";
import Button from "../../atoms/Button/Button";

import Heading from "../../atoms/Heading/Heading";
import Icon from "../../atoms/Icon/Icon";
import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./homeCard.scss";

const BACKGROUND_URL =
    "https://images.unsplash.com/photo-1528828271616-f3e6411aaf6b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80";

export const HomeCard = () => {
    const global = Strapi.useGlobal();
    const [aboutModal, { showAboutModal }] = useAboutModal();

    return (
        <div className="home-card">
            <img
                className="home-card--background"
                src={BACKGROUND_URL}
                alt=""
            />
            <div className="home-card--content">
                <Heading as="h1" className="font-weight-bold">{global.home_heading}</Heading>
                <img
                    className="home-card--logo"
                    src={global.logo.localFile.publicURL}
                    alt="Rynarzewo + Zamość"
                />
                <Paragraph>{global.home_description}</Paragraph>
            </div>

            <Button
                className="btn--circle home-card__info"
                onClick={(_) => showAboutModal()}
                arial-label="Informacje o aplikacji"
            >
                <Icon className="icon-info-min" />
            </Button>

            <Button
                className="btn--primary home-card__button"
                onClick={(_) => navigate("/trasy")}
            >
                Rozpocznij przygodę
            </Button>
        </div>
    );
};

export default HomeCard;
